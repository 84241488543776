import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Contact } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { ITickerActionOverride } from '../../services/common/uniTickerService';
import { UniModalService } from '../../../framework/uni-modal/modalService';
import { ContactModal } from '@app/components/common/contact/modals/contact-modal';
import { ConfirmActions } from '../../../framework/uni-modal';

@Injectable({ providedIn: 'root' })
export class ContactService extends BizHttp<Contact> {
    public actionOverrides: Array<ITickerActionOverride> = [
        {
            Code: 'new_contact',
            ExecuteActionHandler: (parent) => this.onNewContact(parent),
        },
        {
            Code: 'open_contact',
            ExecuteActionHandler: (selectedRows) => this.onOpenContact(selectedRows),
        },
    ];

    constructor(
        http: UniHttp,
        private modalService: UniModalService,
    ) {
        super(http);

        this.relativeURL = Contact.RelativeUrl;
        this.entityType = Contact.EntityType;
        this.DefaultOrderBy = null;
    }

    public onNewContact(parent): Promise<void> {
        if (!parent) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            this.modalService
                .open(ContactModal, {
                    data: {
                        editMode: false,
                    },
                })
                .onClose.subscribe((result) => {
                    if (result && result.action === ConfirmActions.ACCEPT && result.contact) {
                        const contact = result.contact;
                        contact.ParentBusinessRelationID = parent.BusinessRelationID;
                        super.Post(contact).subscribe({
                            next: resolve,
                            error: (err) => {
                                reject(err);
                            },
                        });
                    }
                });
        });
    }

    public onOpenContact(selectedRows: Array<any>): Promise<void> {
        const selectedContact = selectedRows[0];

        if (!selectedContact) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            super.Get(selectedContact.ID, ['Info', 'Info.DefaultPhone', 'Info.DefaultEmail']).subscribe({
                next: (contact) => {
                    this.modalService
                        .open(ContactModal, {
                            data: {
                                editMode: true,
                                contact: contact,
                            },
                        })
                        .onClose.subscribe((result) => {
                            if (result && result.action === ConfirmActions.ACCEPT && result.contact) {
                                super.Put<Contact>(contact.ID, result.contact).subscribe({
                                    next: resolve,
                                    error: (err) => {
                                        reject(err);
                                    },
                                });
                            }
                        });
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    }
}
